<template>
  <v-container fill-height>
    <v-layout align-center>
      <v-flex class="text-center">
        <h1 class="display-4 font-weight-black primary--text text--lighten-1">404</h1>
        <h3
          class="display-2 font-weight-black primary--text text--lighten-2"
        >{{ $t('404') | capitalize }}</h3>
        <p
          class="mt-2 mb-2"
        >{{ $t('redirect') | capitalize }} {{ seconds }} {{ $tc('second', seconds) }}.</p>
        <div>
          <v-btn
            rounded
            large
            class="primary lighten-1"
            dark
            to="/"
          >{{ $t('home') | capitalize }}</v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    seconds: 10,
  }),
  methods: {
    countDownTimer() {
      if (this.seconds > 0) {
        setTimeout(() => {
          this.seconds -= 1;
          this.countDownTimer();
        }, 1000);
      } else if (this.seconds === 0) {
        this.$router.push("/");
      }
    },
  },
  created() {
    this.countDownTimer();
  },
};
</script>
